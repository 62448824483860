<template>
  <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>
  <a-form ref="formRef"
          :model="codeForm"
          :rules="rules"
          :labelCol="{style: 'width: 120px'}"
          :wrapperCol="{span: 8}">

    <div class="basic-info">
      <p class="create-livecode__title title-mark">基础信息</p>
      <a-form-item label="二维码的名称"
                   ref="name"
                   name="name">
        <a-input v-model:value="codeForm.name"
                 placeholder="请输入" />
      </a-form-item>

      <a-form-item label="选择分组"
                   name="groupId">
        <a-select v-model:value="codeForm.groupId"
                  placeholder="请选择分组"
                  :options="options">
        </a-select>
      </a-form-item>

      <a-form-item label="使用成员"
                   ref="staffList"
                   name="staffList">
        <div>
          <a-button class="add-staff-btn"
                    @click="selectStaffModalVisible = true">
            <template #icon>
              <PlusOutlined />
            </template>
            添加成员
          </a-button>
          <span class="staff-tip">同一个二维码可选择多位客服成员进行接待，客户扫码后随机分配一名客服人员</span>
        </div>
        <div class="staff-list">
          <div v-for="(staff, index) in staffNames"
               :key="index"
               class="staff">
            <div v-is="'ww-open-data'"
                 class="ww-open-data"
                 type="userName"
                 :openid="staff"></div>
            <!-- <a-tooltip :title="staff">{{staff}}</a-tooltip> -->
            <CloseCircleOutlined @click="deleteOneStaff(index)" />
          </div>
        </div>

      </a-form-item>
      <select-staff-modal v-model:visible="selectStaffModalVisible"
                          @saveStaff="saveStaff"
                          :defaultCheckedKeys="checkedKeys">
      </select-staff-modal>

      <a-form-item label="客户标签"
                   ref="tagList"
                   name="tagList">
        <a-select v-model:value="codeForm.tagList"
                  :showSearch="false"
                  mode="multiple"
                  placeholder="请选择">
          <a-select-opt-group v-for="corpTagGroup in corpTagList"
                              :key="corpTagGroup.workWechatThirdAppTagGroupId"
                              :label="corpTagGroup.name">
            <a-select-option v-for="tag in corpTagGroup.corpTagList"
                             :key="tag.workWechatThirdAppTagId">
              {{tag.name}}
            </a-select-option>
          </a-select-opt-group>
        </a-select>
      </a-form-item>

    </div>

    <div class="welcome-content">
      <p class="create-livecode__title title-mark">欢迎语</p>

      <div class="tip__container">
        <svg-icon class="color-primary"
                  type="icontishi1"></svg-icon>
        <span class="ml1"> 因企业微信限制，若使用成员已在「企业微信后台」配置了欢迎语，这里的欢迎语将不会生效.</span>
      </div>

      <a-form-item label="设置欢迎语"
                   :name="['welcomeSpeech', 'enabled']">
        <a-radio-group v-model:value="codeForm.welcomeSpeech.enabled">
          <a-radio :value="1">开启</a-radio>
          <a-radio :style="{marginLeft: '44px'}"
                   :value="0">关闭</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item v-if="codeForm.welcomeSpeech.enabled"
                   label="消息内容"
                   name="messageTextContent">
        <div class="welcome-content__container"
             ref="welcomecontentRef">
          <a-button class="insert-btn"
                    type='link'
                    @click="insert('客户昵称')">[插入客户昵称]</a-button>
          <!-- <a-button class="insert-btn"
                    type='link'
                    @click="insert('员工姓名')">[插入员工姓名]</a-button> -->

          <div contenteditable="plaintext-only"
               @input="setMessageContent($event)"
               id="welcomeMessageContent"
               ref="contentRef">
          </div>
          <p :style="{textAlign: 'right'}">{{messageLength || 0}}/
            600</p>
          <div class="upload-list">
            <div class="upload-item"
                 v-for="(content, index) in codeForm.welcomeSpeech.contents"
                 :key="index">
              <template v-if="content.type === 'img' ">
                <span class="item-name">[图片]: <a-tooltip :title="content.imageName">{{content.imageName}}</a-tooltip>
                </span>
              </template>
              <template v-if="content.type === 'link' ">
                <span class="item-name">[链接]: <a-tooltip :title="content.link">{{content.link}}</a-tooltip></span>
              </template>
              <template v-if="content.type === 'file' ">
                <span class="item-name">[PDF]: <a-tooltip :title="content.name">{{content.name}}</a-tooltip></span>
              </template>
              <div class="upload-icons">
                <svg-icon class="icon"
                          type="iconshanchu"
                          @click="deleteAppendix(index)"></svg-icon>
                <svg-icon class="icon"
                          type="iconbianji"
                          @click="editAppendix(content, index)"></svg-icon>
              </div>
            </div>
          </div>
          <a-button class="add-appendix-btn"
                    @click="addAppendix">
            <PlusOutlined />
            添加附件
          </a-button>
          <span :style="{color: '#999', marginLeft: '16px'}">最多可以上传9个附件</span>

          <a-modal :getContainer="() => $refs.welcomecontentRef"
                   class="add-content-modal"
                   width="600px"
                   v-model:visible="addAppendixModalVisible"
                   title="添加附件"
                   @ok="saveContent"
                   @cancel="clearContent(); addAppendixModalVisible = false;">
            <a-tabs v-model:activeKey="content.type">
              <a-tab-pane key="img"
                          tab="添加图片">
                <upload-image class="word-img"
                              :apiName="'channelQrcode'"
                              :imageUrl="content.workWechatMediaId && content.bucketUri"
                              @child-image="handleImgUrl($event)">
                </upload-image>
              </a-tab-pane>
              <a-tab-pane key="link"
                          tab="添加链接"
                          force-render>
                <a-form-item name="link"
                             :wrapper-col="{span: 22}">
                  <a-input v-model:value="content.link"
                           @keyup="getLinkInfo()"
                           placeholder="链接地址请以http:// 或 https://开头">
                  </a-input>
                </a-form-item>
                <a-form-item label="链接标题"
                             name="title"
                             :label-col="{style: 'width: 72px'}"
                             :wrapper-col="{span: 13}">
                  <a-input v-model:value="content.title"></a-input>
                </a-form-item>
                <a-form-item label="链接摘要"
                             name="abstract"
                             :label-col="{style: 'width: 72px'}"
                             :wrapper-col="{span: 13}">
                  <a-input v-model:value="content.summary"></a-input>
                </a-form-item>
                <a-form-item label="链接封面"
                             :label-col="{style: 'width: 72px'}"
                             :wrapper-col="{span: 19}">
                  <upload-image :imageUrl="content.cover"
                                @child-image="handleLinkImg"
                                :apiName="'channelQrcodeLink'">
                  </upload-image>
                </a-form-item>
              </a-tab-pane>
              <a-tab-pane key="file"
                          tab="添加PDF">
                <upload-file apiName='channelQrcode'
                             :fileInfo="content"
                             @child-image="handleFileUpload">
                </upload-file>
              </a-tab-pane>
            </a-tabs>
          </a-modal>
        </div>
      </a-form-item>

      <div class="section-right"
           v-if="codeForm.welcomeSpeech.enabled">
        <p class="phone-title text-bold text-16">客户收到的消息</p>

        <phone-box>
          <li v-if="messageInnerHTML"
              class="reply-list__item">
            <svg-icon class="icon-default-avatar"
                      type="iconmorentouxiang"></svg-icon>
            <div class="msg-text">
              <div v-html="messageInnerHTML"></div>
            </div>
          </li>
          <li v-for="(content, index) in codeForm.welcomeSpeech.contents"
              :key="index"
              class="reply-list__item">
            <svg-icon class="icon-default-avatar"
                      type="iconmorentouxiang"></svg-icon>
            <template v-if="content.type === 'img'">
              <img class="msg-img"
                   :src="content.bucketUri"
                   alt="渠道活码欢迎语附件图">
            </template>
            <template v-else-if="content.type === 'link'">
              <msg-link :msg="{title: content.title, subTitle: content.summary, img: content.cover}"></msg-link>
            </template>
            <template v-else-if="content.type === 'file'">
              <msg-link :msg="{title: content.name, subTitle: renderSize(content.size), img: pdfImgUrl}"></msg-link>
            </template>
          </li>
        </phone-box>
      </div>
    </div>
    <div class="submit-btn">
      <a-button class=""
                type="primary"
                @click="onSubmit"
                :loading="btnLoading">
        修改活码
      </a-button>
    </div>

  </a-form>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import _ from "lodash";
import { Radio, Tabs, message, Tooltip } from "ant-design-vue";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons-vue";
import { useRoute, useRouter } from "vue-router";

import CorpTag from "@/service/api/corpLag.js";
import ChannelQrcodeApi from "@/service/api/channelQrcode";
import ChannelQrcodeGroup from "@/service/api/channelQrcodeGroup";

import RouterHeader from "@/components/RouterHeader.vue";
import selectStaffModal from "@/components/SelectStaffByGroup.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import UploadImage from "@/components/UploadImage.vue";
import UploadFile from "@/components/UploadFile.vue";
import PhoneBox from "@/components/PhoneBox";
import MsgLink from "@/components/MsgLink";

export default defineComponent({
  name: "CreateLiveCode",
  components: {
    RouterHeader,
    selectStaffModal,
    SvgIcon,
    UploadImage,
    UploadFile,
    PhoneBox,
    MsgLink,

    ATooltip: Tooltip,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,

    PlusOutlined,
    CloseCircleOutlined,
  },
  setup() {
    const pdfImgUrl = process.env.VUE_APP_PDF_IMAGE_URL;
    const routerHeaderInfo = [
      {
        path: "/conduction",
        name: "渠道活码",
      },
      {
        name: "编辑活码",
      },
    ];

    const route = new useRoute();
    const corpTagList = ref([]);
    const codeGroup = ref([]);
    const staffInitData = ref([]);
    const options = ref([]);
    async function getTagList() {
      corpTagList.value = await CorpTag.getList();
    }
    async function getGroup() {
      codeGroup.value = await ChannelQrcodeGroup.getList();
      _.forEach(codeGroup.value, (group) => {
        options.value.push({
          value: group.id,
          label: group.name,
        });
      });
    }

    async function getCodeList() {
      const res = await ChannelQrcodeApi.get(route.query.id);
      _.assign(codeForm, res);

      initSelectStaff();
      _.forEach(codeForm.staffs, (value) => {
        staffInitData.value.push(value.name);
      });
    }
    function initSelectStaff() {
      checkedKeys.value = [];
      staffNames.value = [];
      _.forEach(codeForm.staffs, (staff) => {
        checkedKeys.value.push("staff_" + staff.corpStaffId);
        staffNames.value.push(staff.workWechatThirdAppUserId);
      });
    }

    const selectStaffModalVisible = ref(false);
    const staffNames = ref([]);
    const checkedKeys = ref([]);
    function saveStaff(checkedStaffs) {
      codeForm.staffList = [];
      staffNames.value = [];
      checkedKeys.value = [];
      _.forEach(checkedStaffs, (staff) => {
        staffNames.value.push(staff.workWechatThirdAppUserId);
        checkedKeys.value.push(staff.staffId);
        const splitId = _.split(staff.staffId, "_");
        codeForm.staffList.push(Number(splitId[splitId.length - 1]));
      });
      selectStaffModalVisible.value = false;
    }
    function deleteOneStaff(index) {
      staffNames.value.splice(index, 1);
      checkedKeys.value.splice(index, 1);
      codeForm.staffList.splice(index, 1);
    }

    const addAppendixModalVisible = ref(false);
    const welcomecontentRef = ref();
    const contentIndex = ref(0);
    const content = reactive({ type: "img" });
    function addAppendix() {
      if (codeForm.welcomeSpeech.contents.length >= 9) {
        message.error("最多可以上传9个附件");
        return;
      }
      content.index = -1;
      clearContent();

      addAppendixModalVisible.value = true;
    }
    function deleteAppendix(index) {
      codeForm.welcomeSpeech.contents.splice(index, 1);
    }
    function editAppendix(appendix, index) {
      _.assign(content, appendix);
      content.index = index;
      addAppendixModalVisible.value = true;
    }

    function handleImgUrl(event) {
      clearContent();
      content.bucketUri = event.bucketUri;
      content.uploadTime = event.uploadTime;
      content.workWechatMediaId = event.workWechatMediaId;
      content.imageName = event.imageName;
    }
    function handleLinkImg(link) {
      content.cover = link;
    }
    function handleFileUpload(event) {
      _.assign(content, event);
      clearContent("file");
    }
    const getLinkInfo = _.debounce(async () => {
      clearContent("link");
      if (!/^(http|https):/.test(content.link)) {
        content.link = "https://" + content.link;
      }
      let urlInfo = await ChannelQrcodeApi.getUrlBaseInfo({
        url: content.link,
      });

      if (urlInfo.title.length >= 50) {
        urlInfo.title = urlInfo.title.slice(0, 40);
      }

      content.title = urlInfo.title;
      content.summary = urlInfo.desc;
      content.cover = urlInfo.cover;
      validateLink();
    }, 800);

    function saveContent() {
      if (!validateContent()) {
        return;
      }

      const res = _.cloneDeep(content);
      if (res.index >= 0) {
        codeForm.welcomeSpeech.contents[res.index] = res;
      } else {
        codeForm.welcomeSpeech.contents.push(res);
      }
      clearContent();
      addAppendixModalVisible.value = false;
    }
    function clearContent(type) {
      if (_.keys(content).length <= 1) {
        content.type = "img";
        return;
      }

      const appendixType = type || "img";
      if (!type) {
        for (let key of _.keys(content)) {
          delete content[key];
        }
        content.type = appendixType;
        return;
      }

      const imgProps = [
        "index",
        "bucketUri",
        "imageName",
        "uploadTime",
        "workWechatMediaId",
      ];
      const linkProps = ["index", "link", "title", "summary", "cover"];
      const fileProps = [
        "bucketUri",
        "domain",
        "extno",
        "index",
        "name",
        "no",
        "reskey",
        "size",
        "uploadMode",
        "uploadToken",
        "uploadUrl",
      ];

      const props = {
        img: imgProps,
        link: linkProps,
        file: fileProps,
      };

      for (let key of _.keys(content)) {
        if (props[type] && !props[type].includes(key)) {
          delete content[key];
        }
      }
      content.type = appendixType;
    }
    function validateContent() {
      let validate = true;
      let errorcontent = "";
      if (content.type === "img") {
        validate = content.workWechatMediaId;
        errorcontent = "请选择图片";
      } else if (content.type === "link") {
        validate = validateLink()[0];
        errorcontent = validateLink()[1];
      } else if (content.type === "file") {
        validate = content.no;
        errorcontent = "请选择PDF文件";
      }

      if (!validate) {
        message.error(errorcontent);
      }

      return validate;
    }
    function validateLink() {
      const flag = !!(
        content.link &&
        content.title &&
        content.summary &&
        content.cover
      );

      return [flag, "请完善链接内容"];
    }

    const messageInnerHTML = ref("");
    const messageLength = ref("");

    function computedLength() {
      let length = 0;
      if (!contentRef.value) {
        return 0;
      }
      const childNodes = contentRef.value.childNodes;
      _.forEach(childNodes, (node) => {
        if (node.nodeName === "#text") {
          length += node.data.length;
        }
      });
      messageLength.value = length;
    }
    /*光标处插入html代码，参数是String类型的html代码，例子："<p>猪头诺</p>"*/
    function insertHtml(html) {
      var sel, range;
      sel = window.getSelection();
      if (
        sel.focusNode !== contentRef.value &&
        sel.focusNode.parentElement !== contentRef.value
      ) {
        return;
      }

      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        var el = document.createElement("div");
        el.innerHTML = html;
        var frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);
        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    }
    async function insert(value) {
      setFocus(contentRef.value);
      insertHtml(
        `<span contenteditable="false" class="keyword">${value}</span>`
      );

      computedLength();
      messageInnerHTML.value = contentRef.value.innerHTML;
      codeForm.messageTextContent = contentRef.value.textContent;
    }

    document.onkeydown = (event) => {
      var key = window.event ? event.keyCode : event.which;

      if (key.toString() == "13") {
        insertHtml("<br /><br />");
        return false;
      }

      if (
        key.toString() === "8" &&
        contentRef.value.lastChild.nodeName === "SPAN"
      ) {
        contentRef.value.removeChild(contentRef.value.lastChild);
        return;
      }
    };

    const contentRef = ref();
    const setMessageContent = _.debounce((e) => {
      messageInnerHTML.value = e.target.innerHTML;
      codeForm.messageTextContent = e.target.textContent;
      computedLength();

      if (messageLength.value > 600) {
        const length = contentRef.value.childNodes.length;
        findLastTextNode(contentRef.value.childNodes[length - 1]);

        setFocus(contentRef.value);
        messageInnerHTML.value = contentRef.value.innerHTML;
        codeForm.messageTextContent = contentRef.value.textContent;

        computedLength();
      }
    }, 500);
    function setFocus(el) {
      el.focus();
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      //判断光标位置，如不需要可删除
      if (sel.anchorOffset != 0) {
        return;
      }
      sel.removeAllRanges();
      sel.addRange(range);
    }
    function findLastTextNode(node) {
      if (node.nodeType === 3 && node.nodeValue) {
        node.nodeValue = node.nodeValue.slice(0, 600 - messageLength.value);
        return;
      }
      findLastTextNode(node.previousSibling);
    }

    function renderSize(filesize) {
      if (null == filesize || filesize == "") {
        return "0 Bytes";
      }
      var unitArr = new Array("Bytes", "KB", "MB");
      var index = 0;
      var srcsize = parseFloat(filesize);
      index = Math.floor(Math.log(srcsize) / Math.log(1024));
      var size = srcsize / Math.pow(1024, index);
      size = size.toFixed(2); //保留的小数位数
      return size + unitArr[index];
    }

    const btnLoading = ref(false);
    const codeForm = reactive({
      welcomeSpeech: {
        enabled: undefined,
        contents: [],
      },
    });
    const rules = {
      name: [
        {
          required: true,
          message: "请输入二维码名称",
          trigger: "blur",
          whitespace: true,
        },
        {
          min: 1,
          max: 30,
          message: "只能输入1-30个字符",
          trigger: ["change", "blur"],
        },
      ],
      groupId: [
        {
          required: true,
          message: "请选择分组",
          type: "number",
          trigger: "change",
        },
      ],
      staffList: [
        {
          validator: (rule, value) => {
            if (_.keys(value).length) {
              return Promise.resolve();
            }
            return Promise.reject("请选择成员");
          },
        },
      ],
      messageTextContent: [
        {
          required: true,
          message: "请输入欢迎语消息内容",
          trigger: "blur",
          whitespace: true,
        },
      ],
    };

    const formRef = ref();
    const router = new useRouter();
    // function handleTagChange(value, option) {
    //   const tagId = [];
    //   _.forEach(option, (tag) => {
    //     tagId.push(tag.key);
    //   });
    //   codeForm.tagList = tagId;
    // }
    function formatContent() {
      codeForm.welcomeSpeech.content = _.replace(
        messageInnerHTML.value,
        /<span[^>]*>客户昵称[^<]*?<\/span>/g,
        "{nickName}"
      );
      codeForm.welcomeSpeech.content = _.replace(
        codeForm.welcomeSpeech.content,
        /<span[^>]*>员工姓名[^<]*?<\/span>/g,
        "{staffName}"
      );
    }
    function formatContentToInnerHTML() {
      // const myField = document.querySelector("#welcomeMessageContent");
      if (!contentRef.value) {
        return;
      }
      var nickNameReg = new RegExp("{nickName}", "g");
      var staffNameReg = new RegExp("{staffName}", "g");

      let innerHTML = "";
      innerHTML = _.replace(
        codeForm.welcomeSpeech.content,
        nickNameReg,
        `<span contenteditable="false" class="text-keyword">客户昵称</span>`
      );
      innerHTML = _.replace(
        innerHTML,
        staffNameReg,
        `<span contenteditable="false" class="text-keyword">员工姓名</span>`
      );
      const text = document.createTextNode("");
      contentRef.value.appendChild(text);

      contentRef.value.innerHTML = innerHTML;
      messageInnerHTML.value = innerHTML;
      codeForm.messageTextContent = contentRef.value.textContent;
      computedLength();
    }
    function formatEncode() {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&" };

      codeForm.welcomeSpeech.content = _.replace(
        codeForm.welcomeSpeech.content,
        /&(lt|gt|nbsp|amp);/gi,
        function (all, t) {
          return arrEntities[t];
        }
      );
    }

    async function onSubmit() {
      formRef.value
        .validate()
        .then(async () => {
          formatContent();
          formatEncode();
          //调创建活码接口 提交form
          try {
            btnLoading.value = true;
            delete codeForm.messageTextContent;
            await ChannelQrcodeApi.update(codeForm);
            message.success("编辑成功");
            router.push({ path: "conduction" });
          } catch (error) {
            message.error(error.message);
          }
          btnLoading.value = false;
        })
        .catch((err) => {
          console.log(err);
          btnLoading.value = false;
        });
    }

    onMounted(async () => {
      getTagList();
      getGroup();
      await getCodeList();
      formatContentToInnerHTML();
    });

    return {
      pdfImgUrl,
      routerHeaderInfo,
      corpTagList,
      codeGroup,
      options,

      selectStaffModalVisible,
      staffNames,
      checkedKeys,
      saveStaff,
      deleteOneStaff,

      addAppendixModalVisible,
      welcomecontentRef,
      contentIndex,
      content,

      addAppendix,
      deleteAppendix,
      editAppendix,

      handleImgUrl,
      handleLinkImg,
      handleFileUpload,
      getLinkInfo,

      saveContent,
      clearContent,
      setMessageContent,
      messageInnerHTML,

      insert,
      renderSize,

      btnLoading,
      codeForm,
      rules,
      formRef,
      // handleTagChange,
      onSubmit,
      contentRef,
      messageLength,
    };
  },
});
</script>
<style lang='less' scoped>
.create-livecode__title {
  .text-16();
  color: @gray-darker;
  padding-left: @spacing-3x;
  margin-bottom: @spacing-6x;
}

.basic-info {
  position: relative;

  .add-staff-btn {
    color: @gray-dark;
  }

  .staff-tip {
    color: #999;
    font-size: 14px;
    display: inline-block;
    line-height: 32px;
    white-space: nowrap;
  }

  .staff-list {
    display: flex;
    flex-wrap: wrap;

    .staff {
      position: relative;
      margin: 8px 12px 0 0;
      padding: 0 8px;
      width: 80px;
      height: 36px;
      color: @gray-darker;
      text-align: center;
      line-height: 36px;
      background-color: @bg-normal;
      .text-overflow();
      user-select: none;

      .anticon-close-circle {
        position: absolute;
        top: 0;
        right: 0;
        color: @icon;
      }

      &:hover {
        .anticon-close-circle {
          color: @color-primary;
        }
      }
    }
  }
}

.welcome-content {
  position: relative;

  .tip__container {
    padding: 8px;
    margin-left: 12px;
    width: fit-content;
    color: @gray;
    .text-14();
    background: rgba(49, 161, 255, 0.1);
  }

  &__container {
    padding: 0 16px 24px;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    background-color: @bg-normal;

    .upload-list {
      margin-top: 12px;
    }

    .upload-item {
      display: flex;
      justify-content: space-between;
      user-select: none;

      &:not(:last-child) {
        border-bottom: 1px solid @border;
      }

      &:hover {
        .upload-icons {
          display: unset;
        }
      }

      .item-name {
        max-width: 80%;
        .text-overflow();
        word-break: break-all;
      }

      .upload-icons {
        display: none;
        .icon {
          color: @icon;
          font-size: 16px;
          cursor: pointer;
          &:first-child {
            margin-right: 16px;
          }
          &:hover {
            color: @color-primary;
          }
        }
      }
    }

    .insert-btn {
      height: 20px;
      color: #ff761d;
      line-height: 20px;
      padding: 0;
      margin: 0 24px 12px 0;
    }

    .add-appendix-btn {
      color: @color-primary;
      border-radius: 4px;
      border-color: @color-primary;
    }
  }

  .section-right {
    position: absolute;
    top: 24px;
    right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .phone-title {
      margin: 0 auto;
      margin-bottom: 28px;
    }
  }
}

.submit-btn {
  display: flex;
  justify-content: center;
  margin-top: 250px;

  .ant-btn {
    padding: 0 32px;
  }
}
</style>
<style lang="less">
#welcomeMessageContent {
  background-color: @bg;
  width: 100%;
  height: 200px;
  .text-14();
  color: @gray-darker;
  border-radius: @border-radius;
  overflow-y: auto;
  outline: none;
}
</style>